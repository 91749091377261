<script>
import Password from 'vue-password-strength-meter';
import UserApi from '@/api/user.api';
import CountryApi from '@/api/country.api';
import http from '@/services/http';
import cfg from '@/services/cfg';
import { required, email, requiredUnless, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'RegisterUser',

  components: { Password },

  props: {
    profile: Object,
  },

  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
      },
      account: {
        name: '',
        email: '',
        countryCode: '',
        timezone: '',
      },
      country: {
        timezones: [],
      },
      countries: [],
      isRegistering: false,
      isLoggingIn: false,
      provider: null,
      error: null,
    };
  },

  computed: {
    isGoogleSignUp() {
      return this.profile && this.profile.email;
    },
  },

  validations: {
    user: {
      name: { required },
      email: {
        required,
        email,
      },
      password: {
        required: requiredUnless(function() {
          return this.isGoogleSignUp;
        }),
        minLength: minLength(10),
      },
    },
    account: {
      name: { required },
      countryCode: { required },
      timezone: { required },
    },
  },

  async created() {
    this.applyProfile();
    await this.loadCountries();

    this.geoLocate();

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Register',
    });
  },

  methods: {
    applyProfile() {
      const { profile } = this;
      if (!profile) {
        this.redirectIfPossible();
        return;
      }

      const { firstName, lastName, email } = profile;
      const name = `${firstName} ${lastName}`;

      Object.assign(this.user, { name, email });
    },

    changedCountry(country) {
      if (!country) {
        return;
      }

      this.country = country;
      this.account.countryCode = country.code;
      this.account.timezone = country.timezones[0];
    },

    async geoLocate() {
      const { apiKey } = cfg.extremeIpLookup;
      if (!apiKey) {
        return;
      }

      const data = await http
        .get(`https://extreme-ip-lookup.com/json/?key=${apiKey}`, {
          disableAuthHeader: true,
        });

      if (data && data.countryCode) {
        const code = data.countryCode;
        const country = this.countries.find(country => country.code === code);
        this.changedCountry(country);
      }
    },

    async loadCountries() {
      this.countries = await CountryApi.query();
    },

    async register() {
      this.error = null;

      //Validate form
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const { user, account, profile } = this;

      this.isRegistering = true;

      //Registration endpoint returns access token if logged in
      const auth = await UserApi
        .register(user, account, profile)
        .catch(err => this.error = 'errors.' + err.message)
        .finally(() => this.isRegistering = false);

      if (this.error) {
        // // reset error in 5s
        // setTimeout(() => {
        //   this.error = null;
        // }, 5000);
      }
      else {
        const { user: { id, email, name } } = auth;

        this.$engage.identify({
          id,
          email,
          name,
          first_name: name.split(' ').slice(0, 1)[0],
          last_name: name.split(' ').slice(-1)[0],
          created_at: new Date(),
        });

        this.$auth.loginWithToken(auth.access_token);
      }
    },

    async loginWithOAuth(provider) {
      this.provider = provider;
      this.isLoggingIn = true;

      //Login with provider
      await this.$auth.loginWithOAuth(provider, 'register');
    },

    /**
     * Redirect to Login if account already registered
     */
    redirectIfPossible() {
      const { error } = this.$route.query;

      if (error && error === 'exists') {
        return this.$router.push({ name: 'login', params: this.$route.query });
      }
    },
  },
};
</script>

<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm--register">

      <div class="login-cols-wrap">
        <div class="LeftSide">
          <div>
            <div class="FormLogoWrap">
              <img class="Sponsor-logo Sponsor-logoIcon" src="@/assets/logo.svg" alt="Controlla">
            </div>
            <div class="Registration-TitleWrap">
              <h1>{{ $t('portal-forms_register_register.register_title') }}</h1>
              <div class="RegistrationForm-Tagline">{{ $t('portal-forms_register_register.tagline') }}</div>
            </div>

            <div class="Group" :class="{ 'Group--error': $v.account.name.$error }">
            <input v-model="account.name" type="text" class="Input-100" :placeholder="$t('portal-forms_register_register.business_name')">
            <div class="InputHint" v-if="$v.account.name.$error">
              <div v-if="!$v.account.name.required">{{ $t('portal-forms_register_register.business_name_is_required')
                }}</div>
              </div>
            </div>

            <div class="Group" :class="{ 'Group--error': $v.account.countryCode.$error }">
              <select-box class="SelectBox SelectBox--countries" :model="account.countryCode" :options="countries"
                label-by="name" track-by="code" :class="Input - 100" :as-object="true"
                @change="changedCountry($event.value)" />
              <div class="InputHint" v-if="$v.account.countryCode.$error">
                <div v-if="!$v.account.countryCode.required">{{ $t('portal-forms_register_register.country_is_required')
                }}</div>
              </div>
            </div>
            <div class="Group" :class="{ 'Group--error': $v.account.timezone.$error }">
              <select-box class="SelectBox SelectBox--timezones" :model="account.timezone" :options="country.timezones"
                @change="account.timezone = $event.value" />
              <div class="InputHint" v-if="$v.account.timezone.$error">
                <div v-if="!$v.account.timezone.required">{{ $t('portal-forms_register_register.timezone_is_required')
                }}</div>
              </div>
            </div>
            <div class="InputHint" v-if="error">{{ $t(error) }}</div>
          </div>
        </div>
        <div class="RightSide">
          <div v-if="!profile">
            <button class="Button--oauth" @click="loginWithOAuth('google')" :disabled="isLoggingIn">
              <i class="IconProvider"><img src="../../../assets/images/oauth/google.png" alt=""></i>
              {{ $t('portal-forms_register_register.use_google') }}
              <spinner v-if="provider === 'google'" />
            </button>
            <div class="SpacerTextHr">
              <hr />
              <span>{{ $t('portal-forms_register_register.or') }}</span>
              <hr />
            </div>
          </div>

          <div v-if="profile">
            <h2>{{$t('portal-forms_register_register.external_account_connected')}}:</h2>
          </div>

          <div class="Group" :class="{ 'Group--error': $v.user.name.$error }">
            <input v-model="user.name" type="text" class="Input-100"
              :placeholder="$t('portal-forms_register_register.your_name')">
            <div class="InputHint" v-if="$v.user.name.$error">
              <div v-if="!$v.user.name.required">{{ $t('portal-forms_register_register.name_required') }}</div>
            </div>
          </div>
          <div class="Group" :class="{ 'Group--error': $v.user.email.$error }">
            <input
              :disabled="profile"
              v-model="user.email"
              type="text"
              class="Input-100"
              :placeholder="$t('portal-forms_register_register.work_email')"
            >
            <div class="InputHint" v-if="$v.user.email.$error">
              <div v-if="!$v.user.email.required">{{ $t('portal-forms_register_register.email_required') }}</div>
              <div v-if="!$v.user.email.email">{{ $t('portal-forms_register_register.invalid_email') }}</div>
            </div>
          </div>

          <div class="Group" v-if="!profile" :class="{ 'Group--error': $v.user.password.$error }">
            <password
              v-model="user.password"
              :secureLength="10"
              :badge="false"
              :placeholder="$t('portal-forms_register_register.password')"
            />
            <div class="InputHint" v-if="$v.user.password.$error">
              <div v-if="!$v.user.password.required">{{ $t('portal-forms_register_register.password_is_required') }}
              </div>
              <div v-if="!$v.user.password.minLength">{{ $t('portal-forms_register_register.password_at_least') }}</div>
            </div>
          </div>
          <div class="Group">
            <button class="Button--primary Button--register" @click="register" :disabled="isRegistering">
              <i class="Icon-Lock">lock</i>{{ $t('portal-forms_register_register.create_account') }}
              <spinner />
            </button>
          </div>
          <div class="Alternate-UserOption--terms">
            {{ $t('portal-forms_register_register.by_clicking') }} <a target="_blank"
              href="https://www.controlla.io/legal/terms">{{
              $t('portal-forms_register_register.terms_of_use')
              }}</a>
            {{ $t('portal-forms_register_register.and_confirms') }} <a target="_blank"
              href="https://www.controlla.io/legal/privacy">{{ $t('portal-forms_register_register.privacy_policy')
              }}</a>.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style  lang="scss">
.PortalForm--register {
  padding: 4.5rem 3rem 2.5rem 3rem;
  text-align: left;

  .FormLogoWrap {
    margin-top: 0;
  }

  .Sponsor-logoIcon {
    width: 200px;
    margin-left: -7px;
    margin-top: 0;
  }

  .FormLogoWrap .Sponsor-logo,
  .Registration-TitleWrap {
    padding-left: 11px;

    h1 {
      margin-bottom: $spacingXS;
      padding-left: 0;
    }

    .RegistrationForm-Tagline {
      color: $colorTextFaded2;
      font-weight: $fontWeightNormal;
      margin-bottom: 3rem;
    }
  }
}

.login-cols-wrap {
  display: flex;
}

.LeftSide,
.RightSide {
  flex: 1;
  margin: 0 $spacingXL;
  padding: $spacingXL 0 0 0;
}

.LeftSide {
  padding-top: 3rem;
}

.RightSide {
  padding-left: 45px;
  border-left: 1px solid $colorBordersLight;
  margin: 0 0 0 2rem;
}

.SelectBox--countries {
  width: 100%;
}

.Password {
  flex: 1;
}

.Password__strength-meter {
  height: 10px ! important;
  background: $colorLightGray ! important;
  margin: 8px auto 0px ! important;

  &:before {
    content: inherit ! important;
  }

  &:after {
    content: inherit ! important;
  }

  .Password__strength-meter--fill {
    &[data-score~="3"] {
      background-color: #cdc132;
    }

    &[data-score~="4"] {
      background-color: #30cf30;
    }
  }
}

.SelectBox--countries .SelectBox-options,
.SelectBox--timezones .SelectBox-options {
  width: 20rem;
}
</style>
