<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: state => state.session.user,
      dropMenuOpen: state => state.session.dropMenuOpen,
    }),
  },
  methods: {
    toggle_menu() {
      const { user } = this;
      user.experiences.main_menu_open = !user.experiences?.main_menu_open;
      user.updateOwn(user);
    },
  },
};
</script>

<template>
  <div class="MenuBar">
    <div class="Menu-item--burger">
      <a class="MainNav-link Tooltip--right Tooltip--right" @click="toggle_menu()">
        <i class="Icon-MainNav Icon-MainNav--burger">menu</i>
        <span class="MenuTitle-full">{{ $t('app_header_userlinks.shrink_menu') }}</span>
        <div v-if="!dropMenuOpen" class="Tooltip-text">{{ $t('app_header_userlinks.expand_menu') }}</div>
      </a>
    </div>
    <div class="Menu-item">
      <router-link class="MainNav-link Tooltip--right" to="/workspace/"
        :class="{ 'router-link-exact-active': $route.path.startsWith('/work') }">
        <i class="Icon-MainNav">workspaces</i> <span class="MenuTitle-full">{{ $t('app_header-menu-bar.workspace')
        }}</span>
        <div class="Tooltip-text">{{ $t('app_header-menu-bar.workspace') }}</div>
      </router-link>
    </div>
    <div class="Menu-item">
      <router-link class="MainNav-link Tooltip--right" to="/billing"
        :class="{ 'router-link-exact-active': $route.path.startsWith('/billing') }">
        <i class="Icon-MainNav">attach_money</i> <span class="MenuTitle-full">{{ $t('app_header-menu-bar.billing')
        }}</span>
        <div class="Tooltip-text">{{ $t('app_header-menu-bar.billing') }}</div>
      </router-link>
    </div>
    <div class="Menu-item">
      <router-link class="MainNav-link Tooltip--right" to="/directory"
        :class="{ 'router-link-exact-active': $route.path.startsWith('/directory') }">
        <i class="Icon-MainNav">people</i> <span class="MenuTitle-full">{{ $t('app_header-menu-bar.directory') }}</span>
        <div class="Tooltip-text">{{ $t('app_header-menu-bar.directory') }}</div>
      </router-link>
    </div>
    <div class="Menu-item" v-if="user.role === 'Admin'">
      <router-link class="MainNav-link Tooltip--right" to="/integrations"
        :class="{ 'router-link-exact-active': $route.path.startsWith('/integrations') }">
        <i class="Icon-MainNav">extension</i>
        <span class="MenuTitle-full">{{ $t('app_header-menu-bar.integrations') }}</span>
        <div class="Tooltip-text">
          {{ $t('app_header-menu-bar.integrations') }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
.MenuBar {
  // margin-top: -1.45rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Menu-Closed .MenuBar {
  // width: 48px;
}
.Menu-Closed .MenuBar {
  // width: 48px;
}

.Menu-Closed .MainNav-link .MenuTitle-full {
  display: none;
}

.Menu-item {
  margin: 10px 0rem;
}

.Menu-item--burger {
  margin: 0;
  padding: 0 0 40px;
  // border-bottom: 1px solid $colorWhiteFaded3;
  .MenuTitle-full {
    font-size: $fontSizeXS;
    color: $colorWhiteFaded2;
    margin-left: 10px;
  }
}

.MenuTitle-full {
  margin-left: 16px;
  font-weight: $fontWeightNormal;
  font-size: $fontSizeS;
}


.Menu-Open .MainNav-link {
  width: 176px;
}

.MainNav-link,
.MainNav-link .Icon-MainNav {
  cursor: pointer;
  text-decoration: none;
  color: $colorIconMainNav;
  transition: background .2s ease-in-out;
  // padding: 4px 4px;
  width: auto;
  display: flex;
  align-items: center;

  &:hover {
    color: $colorWhite;
  }

  &.router-link-exact-active {
    background: $colorMainMenuActiveBg;
    border-radius: $borderRadiusM;
  }

  &.MainNav-link--nowash.MainNav-link--avatar.Tooltip--right.router-link-exact-active {
    &:hover {
      color: $colorMainMenuActive;
    }
  }
  &.router-link-exact-active,
  &:hover {
    color: $colorMainMenuActive;
  }
}
</style>
