<script>
import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import _ from 'lodash';
import cfg from '@/services/cfg';
import PlanApi from '@/api/plan.api';
import AccountMenu from './account-menu';
import PlanSelect from './plan-select';
import ModalRemove from '@/components/shared/modal-remove';
import AccountApi from '@/api/account.api';

export default {
  name: 'AccountBilling',

  components: { AccountMenu, PlanSelect },

  data() {
    return {
      model: {
        plan: null,
        billing: {
          contact: '',
          email: '',
          entity: '',
          interval: '',
        },
      },
      original: {},
      plans: [],
      isSaving: false,
      wantsToChangeCard: false,
      cardError: null,
      changed: false,
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
      user: state => state.session.user,
    }),

    hasCard() {
      return !!this.account.stripe?.card;
    },
  },

  validations: {
    model: {
      billing: {
        entity: { required },
      },
      plan: { required },
    },
  },

  created() {
    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Global Settings',
    });

    this.model = this.account.extract(Object.keys(this.model));

    if (!this.model.billing) {
      this.model.billing = {
        contact: '',
        email: '',
        entity: '',
      };
    }

    if (!this.model.billing.interval) {
      this.model.billing.interval = 'month';
    }

    this.loadPlansList();
    this.original = _.cloneDeep(this.model);
  },

  mounted() {
    this.setupStripeService();
  },

  methods: {
    async loadPlansList() {
      await PlanApi
        .list()
        .then(plans => this.plans = plans);
    },

    onChangedPlan(plan, interval) {
      plan = plan ? plan.id : null;
      this.model.plan = plan;
      this.model.billing.interval = interval
      this.onChange();
    },

    setupStripeService() {
      //Load service for global public key
      this.stripe = this.$stripe.service(cfg.stripe.publicKey);

      //Mount card element on next tick, unless we already have a card
      if (!this.hasCard) {
        this.$nextTick(() => {
          this.mountCardElement();
        });
      }
    },

    mountCardElement() {
      //Wrap to capture errors as card errors
      try {
        //Create card element
        this.card = this.stripe.elements().create('card');

        this.card.addEventListener('change', ({error}) => {
          this.cardError = error ? error.message : '';
        });

        this.card.mount(this.$refs.card);
      }
      catch (error) {
        this.cardError = error.message;
      }
    },

    /**
     * Change existing card
     */
    changeCard() {
      this.wantsToChangeCard = true;
      this.changed = true;

      //Mount card element on next tick
      this.$nextTick(() => {
        this.mountCardElement();
      });
    },

    async save() {
      const { $v, account, model } = this;

      //Validate
      $v.$touch();
      if ($v.$invalid) {
        return;
      }

      this.isSaving = true;

      try {
        //Create token
        const { card } = this;

        if (card) {
          const { token, error } = await this.stripe.createToken(card);
          if (error) {
            throw error;
          }

          //Append token to model
          model.stripe = { token };
          await account.updateOwnBilling(model);
        }
        if (model.plan) {
          await account.updateOwnPlan(model)
            .catch(console.error);

          if (window.Beacon) {
            const chosenPlan = this.plans.find(p => p.id === model.plan);
            window.Beacon('identify', {
              email: this.user.email,
              name: this.user.name,
              plan: chosenPlan.name,
            });
          }
        }
      }
      catch (error) {
        //Ignore actual exceptions
        if (error instanceof Error) {
          return;
        }

        this.cardError = error.message;
      }
      finally {
        this.$store.dispatch('session/loadAccount');

        this.$notice.show('notices.plan_saved');
        this.isSaving = false;
        this.wantsToChangeCard = false;
        this.original = _.cloneDeep(this.model);

        this.onChange();
      }
    },

    onChange() {
      this.changed = !_.isEqual(this.original, this.model);
    },

    _delete() {
      const onRemove = () => {
        return AccountApi.archive()
          .then(() => this.$auth.logout());
        // MHJB: should show error messages
      };

      this.$modal.show(ModalRemove, {
        singular: 'account',
        onRemove,
        confirmationText: `${this.$t('shared_modal-remove.yes_i_am_sure')} <strong>${this.account.name}</strong>`,
      });
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
        <account-menu />
      </div>
      <div class="Content--fullWidth">
        <h1 class="PageTitle">{{$t('account_billing.h1')}}</h1>
        <form @input="onChange">
          <div class="GroupSet">
              <div class="Group Group--inline" :class="{'Group--error': $v.model.billing.entity.$error}">
              <div class="Label Label--inline">{{$t('account_billing.entity')}}</div>
              <input v-model="model.billing.entity" autocomplete="off" type="text" class="Input-M"
                :placeholder="$t('account_billing.entity')">
              <div class="InputHint" v-if="$v.model.billing.entity.$error">
                <p v-if="!$v.model.billing.entity.required">{{$t('errors.required', {field:
                  $t('account_billing.entity')})}}</p>
              </div>
            </div>
            <div class="Group Group--inline" v-if="hasCard && !wantsToChangeCard">
              <div class="Label Label--inline">{{$t('account_billing.billing_card')}}</div>
              <p class="Card--infoIndent">
                {{account.stripe.card.brand}}
                ***{{account.stripe.card.last4}}
                <small>
                  | <a @click="changeCard">{{$t('account_billing.change_card')}}</a>
                </small>
              </p>
            </div>
            <div class="Group Group--inline" v-if="!hasCard || wantsToChangeCard">
              <div class="Label Label--inline">{{hasCard ? $t('account_billing.new_card') :
                $t('account_billing.card')}}:</div>
              <div class="Input-M">
                <div ref="card"></div>
              </div>
              <div class="InputHint" v-if="cardError">
                <p>{{cardError}}</p>
              </div>
            </div>
            <div class="Plan-Select--wrap">
              <div class="" :class="{'Group--error': $v.model.plan.$error}">
                <div class="Message--wrap"
                  v-if="account.subscriptionStatus === 'trial' || account.subscriptionStatus === 'expired_trial'"
                >
                  <div class="Message-Warning">
                    <div class="Message-Header--compact">
                      <i class="Icon-Base--warning">error_outline</i>
                      <div class="Message-Title">
                        <div class="Message-Emphasis">
                          {{$t('account_billing.presently_on_trial')}} {{account.trialEndDate | moment('from')}}
                        </div>
                      </div>
                      </div>
                    <div class="SubMessage">
                      {{$t('account_billing.choose_a_plan')}}
                    </div>
                  </div>
                </div>
                <plan-select
                  :model="model.plan"
                  :originalPlan="original.plan"
                  :originalInterval="original.billing.interval"
                  :plans="plans"
                  :choose="onChangedPlan"
                  :interval="model.billing.interval"
                />
                <div class="InputHint" v-if="$v.model.plan.$error">
                  <p v-if="!$v.model.plan.required">{{$t('account_billing.plan_error')}}</p>
                </div>
              </div>
            </div>
            <div class="button-flex-wrap">
              <div class="Group-inline Group--button">
                <button-bar :is-busy="isSaving" :show-cancel="false" :label-confirm="$t('shared_button-bar.confirm')"
                  @confirm="save" :isDisabledConfirm="$v.$invalid || !changed"></button-bar>
              </div>
            </div>
            <div class="SpacerXL"></div>
            <div class="SpacerXL"></div>
            <div class="SpacerXL"></div>
            <a class="Button--justText-deleteAccount" @click="_delete()">
              {{$t('account_billing.delete_account')}}
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.button-flex-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Card--infoIndent {
  padding-left: $spacingM;
  padding-top: 0.25rem;

}

.nobr {
  white-space: nowrap;
}

.PaymentHistory {
  margin-top: $spacingM;
  list-style: none;
  li {
    margin-bottom: $spacingM;
    display: flex;
    .date {
      width: 7rem;
    }
    .amount {
      width: 7rem; }
  }
}

.TrialNotice {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: $fontSizeXL;
}

</style>
