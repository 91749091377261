
//Vue app component
import Vue from 'vue';
import App from '@/components/app/app';

//Services
import router from './router';
import store from './store';
import cfg from './services/cfg';
import auth from './services/auth';
import filter from './services/filter';
import errors from './services/errors';
import stripe from './services/stripe';
import engage from './services/engage';

//Service worker
// MHJB: Off for the time being
// import './register-service-worker';

//Global styles
import './styles/reset.scss';
import './styles/utility.scss';
import './styles/elements.scss';
import './styles/shared.scss';

//3rd party plugins and components
import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import { Tabs, Tab } from './components/shared/tabs';
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionSeparator: '.',
  fractionCount: 2,
  symbolPosition: 'front',
  symbolSpacing: false,
});

import CurrencyStyler from '@/components/shared/currency-styler';
Vue.component('currency-styler', CurrencyStyler);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

//Plugins
import Notice from './plugins/notice/notice.plugin';
Vue.use(Notice, {});

import Format from './plugins/format/format.plugin';
Vue.use(Format, {});

import vueModal from './plugins/modal/modal.plugin';
Vue.use(vueModal, {
  dynamicDefaults: {
    clickToClose: false,
    scrollable: true,
    adaptive: true,
    height: 'auto',
    width: Infinity,
    maxWidth: 600,
  },
});

import HtmlID from './plugins/htmlid/htmlid.plugin';
Vue.use(HtmlID, {});

//Shared components
import Spinner from '@/components/shared/spinner';
Vue.component('spinner', Spinner);

import SelectBox from '@/components/shared/select-box';
Vue.component('selectBox', SelectBox);

import TypeAhead from '@/components/shared/type-ahead';
Vue.component('typeAhead', TypeAhead);

import CheckBox from '@/components/shared/check-box';
Vue.component('checkBox', CheckBox);

import CheckBoxes from '@/components/shared/check-boxes';
Vue.component('checkBoxes', CheckBoxes);

import DatePicker from '@/components/shared/date-picker';
Vue.component('datePicker', DatePicker);

import ButtonBar from '@/components/shared/button-bar';
Vue.component('buttonBar', ButtonBar);

import Sort from '@/components/shared/sort';
Vue.component('sort', Sort);

import Pagination from '@/components/shared/pagination';
Vue.component('pagination', Pagination);

import FormInput from '@/components/shared/form-input';
Vue.component('form-input', FormInput);

import FormGroup from '@/components/shared/form-group';
Vue.component('form-group', FormGroup);

import Provider from 'vue-provider';
Vue.component('provider', Provider);

import Icon from '@/components/shared/icons/icon';
Vue.component('icon', Icon);

import FrillWidget from '@/components/shared/frill-widget';
Vue.component('frill-widget', FrillWidget);

import ModalLink from '@/components/shared/modal-link';
Vue.component('modal-link', ModalLink);

import CurrencyInput from '@/components/shared/currency-input';
Vue.component('CurrencyInput', CurrencyInput);

import SettingOptions from '@/components/shared/setting-options';
Vue.component('setting-options', SettingOptions);

import PageMeta from '@/components/shared/page-meta';
Vue.component('page-meta', PageMeta);

import IconsServices from '@/components/shared/icons-services';
Vue.component('icons-services', IconsServices);

//Filters
import initFilters from '@/filters';
initFilters(Vue);

//Additional key codes
Vue.config.keyCodes.backspace = 8;
Vue.config.keyCodes.comma = 188;

//Global Vue configuration
Vue.config.productionTip = false;
Vue.config.errorHandler = function(error) {
  errors.process(error);
};

import i18n from './i18n';

//Instantiate app
let vueInstance = new Vue({
  router,
  store,
  cfg,
  auth,
  filter,
  errors,
  stripe,
  engage,
  i18n,
  render: h => h(App),
}).$mount('#app');

//Expose instance for debugging in development mode
if (process.env.NODE_ENV === 'development') {
  window.vueInstance = vueInstance;
}