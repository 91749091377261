<template>
    <div class="Container Container--centered Container--padded">
    <div class="PortalForm">
      <div class="PortalForm-Content">
        <div class="FormLogoWrap">
          <img
            class="Sponsor-logo Sponsor-logoIcon"
            src="@/assets/logo.svg"
            alt="Controlla"
          >
        </div>
      <h1>{{$t('portal-forms_reset-password_reset-password.reset_password')}}</h1>
      <div v-if="isErrorTokenExpired">
        <div class="Section">
          {{$t('portal-forms_reset-password_reset-password.link_expired')}}.
        </div>
      </div>
      <div v-else-if="isReset">
        <div class="Section">
          {{$t('portal-forms_reset-password_reset-password.password_was_reset')}}
        </div>
      </div>
      <div v-else>
        <div class="Section">
          {{$t('portal-forms_reset-password_reset-password.enter_new_password')}}:
        </div>
        <div class="Group">
          <input
            v-model="password"
            type="password"
            class="Input-100"
            :placeholder="$t('portal-forms_reset-password_reset-password.password_placeholder')"
          >
        </div>
        <div class="Group">
          <button
            class="Button--plain"
            @click="reset()"
            :disabled="isResetting"
          >
          {{$t('portal-forms_reset-password_reset-password.reset_password')}}
            <spinner />
          </button>
        </div>
      </div>
      <div class="Alternate-UserOption">
        <router-link to="/login">{{$t('portal-forms_reset-password_reset-password.back_to_login')}}</router-link>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import NotAuthenticatedError from '@/errors/not-authenticated';

export default {
  props: {
    accessToken: String,
  },

  data() {
    return {
      password: '',
      isResetting: false,
      isReset: false,
    };
  },

  created() {
    this.$auth.setToken(this.accessToken);

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Reset password',
    });
  },

  methods: {
    async reset() {
      const {password} = this;

      this.isResetting = true;

      //Register
      try {
        await UserApi.changePassword(password);
        this.isReset = true;

        //Clear temporary token again
        this.$auth.clearToken();
      }
      catch (error) {
        if (error instanceof NotAuthenticatedError) {
          return this.isErrorTokenExpired = true;
        }
      }
      finally {
        this.isResetting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
