<script>
import { mapState } from 'vuex';
import ItemHistory from '@/components/shared/item-history';

export default {
  components: { ItemHistory },

  data() {
    return {
      isError: false,
      isSaving: false,
      isRemoving: false,
      wantsToRemove: false,
    };
  },

  computed: {
    ...mapState({
      fromLink: state => state.session.fromLink,
    }),

    bylineCapitalised() {
      return this.byline.charAt(0).toUpperCase() + this.byline.slice(1);
    },

    settingOptions() {
      let actions = [];
      ['onEdit', 'onRemove', 'onArchive'].filter(option => {
        const action = option.slice(2).toLowerCase();
        if (this[option]) {
          actions.push(action)
        }
      });
      return actions;
    }
  },

  props: {
    item: { type: Object },
    itemName: { type: String },
    byline: { type: String },
    icon: { type: String },
    iconFilled: { type: Boolean },
    singular: { type: String },
    onEdit: { type: Function },
    onRemove: { type: Function },
    onArchive: { type: Function },
    onSave: { type: Function },
    isEdit: { type: Boolean },
    editable: {
      type: Boolean,
      default: true,
      required: false,
    },
    showHistory: {
      type: Boolean,
      default: true,
      required: false,
    },
    saveable: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    toggleRemoveIntent() {
      this.wantsToRemove = !this.wantsToRemove;
    },

    edit() {
      this
        .onEdit()
        .then(() => this.$emit('close'));
    },

    remove() {
      this.isRemoving = true;
      this.isError = false;
      this
        .onRemove()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isRemoving = false);
    },

    archive() {
      this.isArchiving = true;
      this.isError = false;
      this.onArchive(this.item);
      this.$emit('close');
    },

    close() {
      this.$store.commit('session/clearFromLink');
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="FullView-Modal">
    <div class="Modal-wrap">
      <div class="Modal FullHeight">
         <div class="Modal-Header">
              <div>
                <!-- MHJB: Disabling for now, as causes double-popups on return, when combined with
                url-with-ever-modal -->
                <!-- <router-link
                  class="Button--backlink"
                  v-if="fromLink"
                  :to="fromLink.link"
                  @click.native="close"
                >
                  <i class="Icon-Base">arrow_back</i> 
                  <span>{{ $t('global.back_to') }} {{ fromLink.name }}</span>
                </router-link> -->

                <h1 class="ItemL">
                  <div class="Item-Icon">
                    <icons-services v-if="itemName === 'contract'"  :period="item.recurrence.interval" />
                    <icon v-else-if="!iconFilled" :name="icon" class="Icon-Base" />
                    <icon v-else :name="icon" class="Icon-Base--filled" />
                  </div>
                  <div class="Item-TextWrap">
                    <div class="Item-Headline">{{ singular }}</div>
                    <div class="Description" v-if="byline">{{ bylineCapitalised }}</div>
                  </div>
                </h1>
              </div>
              <div v-if="editable">
                <setting-options
                  v-if="settingOptions.length"
                  :options="settingOptions"
                  @edit="edit(item)"
                  @remove="remove(item)"
                  @archive="archive(item)"
                />
              </div>
            </div>

            <div class="Modal-content">
              <div class="ModalBody">
                <slot />
              </div>
            </div>
            <div class="ModalFooter">

              <!-- Remove bar -->
              <button-bar v-if="wantsToRemove" :is-busy="isRemoving" label-confirm="Remove" type-confirm="danger"
                @cancel="toggleRemoveIntent" @confirm="remove">
                <p>{{ $t('modal_view.are_you_sure_you_want_remove') }} {{ singular }}?</p>
              </button-bar>

              <!-- Regular bar -->
              <button-bar v-if="saveable" :show-confirm="false" label-cancel="Save" @cancel="onSave">
              </button-bar>
            </div>
        </div>
        <div class="SidePanel">
          <div class="SidePanel-Header">
            <i @click="close" class="Icon-ModalsClose">close</i>
          </div>
          <div class="SidePanel-Content">
            <item-history v-if="showHistory" class="ModalHistory" :limit="10" :item="item" />
          </div>
        </div>
      </div>
  </div>
</template>
