<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm--payInvoice">
      <div class="GroupSet" v-if="isLoading">
        <div class="Pay-Invoice--loading">
          <span>{{$t('portal-forms_pay-invoice_pay-invoice.loading_invoice')}}</span>
          <spinner class="Spinner--inline Spinner--general" />
        </div>
      </div>
      <div v-if="isIntegrationError">
        <h1>{{$t('portal-forms_pay-invoice_pay-invoice.payment_not_possible_title')}}</h1>
        <p>
          {{$t('portal-forms_pay-invoice_pay-invoice.payment_not_possible_start')}}&nbsp;{{account.name}}&nbsp;
          {{$t('portal-forms_pay-invoice_pay-invoice.payment_not_possible_end')}}
        </p>
      </div>
      <div class="GroupSet" v-if="!isLoading && !isIntegrationError">
        <h1>{{$t('portal-forms_pay-invoice_pay-invoice.invoice')}} {{invoice.number}}</h1>
        <p>
          {{$t('portal-forms_pay-invoice_pay-invoice.account')}}: {{account.name}}<br>
          {{$t('portal-forms_pay-invoice_pay-invoice.contact')}}: {{invoice.contact.name}}<br>
          {{$t('portal-forms_pay-invoice_pay-invoice.amount')}}: {{invoice.totalInclTax | currency}}<br>
          {{$t('portal-forms_pay-invoice_pay-invoice.due_date')}}: {{invoice.dueDate | moment('ll')}}
        </p>
        <hr>

        <div class="GroupSet" v-if="!invoice.isPaid">
          <div class="Group">
            <div ref="card"></div>
            <div class="InputHint" v-if="cardError">
              <p>{{cardError}}</p>
            </div>
          </div>

          <div class="Group">
            <button class="Button--plain" @click="pay" :disabled="isPaying">
              {{$t('portal-forms_pay-invoice_pay-invoice.pay_now')}}
              <spinner />
            </button>
          </div>
        </div>
        <div class="GroupSet" v-else>
          <p>{{$t('portal-forms_pay-invoice_pay-invoice.already_paid')}} {{invoice.paidDate | moment('ll')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountApi from '@/api/account.api';
import InvoiceApi from '@/api/invoice.api';
import IntegrationApi from '@/api/integration.api';

export default {
  props: {
    invoiceId: String,
    accessToken: String,
  },

  data() {
    return {
      isLoading: true,
      isPaying: false,
      isIntegrationError: false,
      account: null,
      invoice: null,
      integrations: null,
      cardError: null,
    };
  },

  created() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Pay invoice',
    });
  },

  async mounted() {
    await Promise.all([
      this.loadAccount(),
      this.loadInvoice(),
      this.loadStripeIntegration(),
    ]);

    this.isLoading = false;

    this.setupStripeService();
  },

  methods: {
    async loadAccount() {
      this.account = await AccountApi.own();
    },

    async loadInvoice() {
      this.invoice = await InvoiceApi.findByIdForPayment(this.invoiceId);
      this.isPaid = this.invoice.isPaid;
    },

    async loadStripeIntegration() {
      const { accessToken } = this;
      this.integration = await IntegrationApi.findByType('stripe', accessToken);
    },

    setupStripeService() {

      //Not connected yet?
      if (!this.integration.isConnected) {
        return this.isIntegrationError = true;
      }

      //Get public key from integration
      const { publicKey } = this.integration.subclassPublic;

      //Load service for this public key
      this.stripe = this.$stripe.service(publicKey);

      this.$nextTick(() => {
        this.mountCardElement();
      });
    },

    mountCardElement() {

      //Wrap to capture errors as card errors
      try {

        //Create card element
        this.card = this.stripe
          .elements()
          .create('card');

        //Apply listener
        this.card.addEventListener('change', ({ error }) => {
          this.cardError = error ? error.message : '';
        });

        //Mount element
        this.card.mount(this.$refs.card);
      }
      catch (error) {
        this.cardError = error.message;
      }
    },

    /**
     * Pay now
     */
    async pay() {
      this.isPaying = true;

      try {
        const method = 'stripe';
        const { invoice, card } = this;
        const { token, error } = await this.stripe.createToken(card);
        if (error) {
          throw error;
        }

        //Create payment data
        const stripe = { token };
        const data = { method, stripe };

        await invoice.pay(data);
      }
      catch {
        this.cardError = this.$t('portal-forms_pay-invoice_pay-invoice.payment_failed');
      }
      finally {
        this.isPaying = false;
      }
    },
  },
};
</script>

<style lang="scss">
.Pay-Invoice--loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
