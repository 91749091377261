<script>
import { mapState } from 'vuex';
import InvoiceApi from '@/api/invoice.api';
import { SENT, PAID, VOID } from '@/constants/invoice-statuses';
import InvoiceView from './modals/view';
import InvoiceEdit from './modals/edit';
import OverviewOnboarding from '@/components/shared/overview-onboarding.vue';
import InvoiceMarkpaid from './modals/mark-paid';
import InvoiceSend from './modals/send';

export default {
  components: { OverviewOnboarding },

  data() {
    return {
      title: 'Invoices',
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      invoices: [],
      optionObj: {
        draft: ['view', 'edit', 'send'],
        sent: ['view', 'markPaid'],
        paid: ['view'],
      },
      isLoading: false,
      SENT, PAID, VOID,
      meta: {
        sent: 0,
        draft: 0,
        paid: 0,
        void: 0,
      },
    };
  },

  computed: {
    ...mapState({
      account: state => state.session.account,
      exclTax: state => state.session?.user?.experiences?.exclTax,
      user: state => state.session.user,
    }),

    hasItems() {
      return this.invoices.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  created() {
    this.setupFilter();
    this.setupPage();
    this.loadInvoices();
    this.processAction();
  },

  methods: {
    setupFilter() {
      this.filter = this.$filter.get('invoices');

      this.filter.setDefaults({
        search: '',
        sort: '-number',
        from: null,
        to: null,
        status: SENT,
      });

      this.filter.loadValues(this.$route.query);

      this.filter.onChange(() => {
        this.loadPage(1);
      });
    },

    setupPage() {
      const { title, filter } = this;
      this.$store.dispatch('page/setup', { title, filter });
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'edit' && id) {
        const invoice = await InvoiceApi.findById(id);
        if (invoice) {
          this.edit(invoice);
        }
      }

      if (action === 'view' && id) {
        const invoice = await InvoiceApi.findById(id);
        if (invoice) {
          this.view(invoice);
        }
      }
    },

    view(invoice) {
      const { account } = this;

      const onEdit = async () => {
        this.edit(invoice);
      };

      this.$modal.show(
        InvoiceView,
        { account, invoice, onEdit },
        { maxWidth: 1000 },
        // { 
        //   opened: () => this.$router.push({ name: 'invoices', params: { action: 'view', id: invoice.id } }),
        //   closed: () => this.$router.replace({ name: 'invoices' }),
        // }
      );
    },

    edit(invoice) {
      const { account } = this;

      const onSave = model => invoice.save(model)
        .then(() => this.onSaved(invoice));

      this.$modal.show(InvoiceEdit, { account, invoice, onSave }, { maxWidth: 800 });
    },

    markPaid(invoice) {
      const onMarkPaid = model => invoice
        .markPaid(model)
        .then(this.onPaid);

      this.$modal.show(InvoiceMarkpaid, { invoice, onMarkPaid });
    },

    send(invoice) {
      const onSend = model => invoice
        .send(model)
        .then(this.onSent);

      this.$modal.show(InvoiceSend, { invoice, onSend });
    },

    onSaved(invoice) {
      this.$notice.show('notices.invoice_saved');
      this.view(invoice);
    },

    onPaid() {
      this.$notice.show('notices.invoice_paid');
      this.loadInvoices(true);
    },

    onSent() {
      this.$notice.show('notices.invoice_sent');
    },

    loadPage(page) {
      this.page = page;
      this.loadInvoices(true);
    },

    async loadInvoices() {
      this.isLoading = true;
      const filter = this.makeFilter();
      await InvoiceApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);

    },

    makeFilter(extra) {
      //Initialize filter
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const { meta, invoices } = data;
      this.invoices = invoices;
      this.totalItems = meta.total;
      this.meta = meta;
    },

    toggleExclTax() {
      const { user } = this;
      user.experiences.exclTax = !user.experiences?.exclTax;
      user.updateOwn(user);
    },
  },
};
</script>

<template>
  <div class="Page">
    <page-meta
      type="invoices_overview.title"
      :title="title"
      :filter="filter"
      :total-items="totalItems"
      :showAddButton=false
      :num-pages="numPages"
      :page="page"
      :load-page="loadPage"
    >
      <template slot="settings">
        <setting-options>
          <li>
            <router-link to="/workspace/assets/assetTypes"><i class="Icon-Base">widgets</i>{{$t('settings_workspace.menu_asset_types')}}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'integrations' }"><i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}</router-link>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_invoices_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_invoices')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>
      <template slot="tabs">
        <div class="PageMeta-tab--first" :class="{ 'is-active': filter.status === SENT }"
          @click="filter.update('status', SENT)">
          {{ $t('invoices_overview.header_open') }}
          <span class="TabCount--neutral">{{ meta.sent }}</span>
        </div>
        <div class="PageMeta-tab" :class="{ 'is-active': filter.status === PAID }"
          @click="filter.update('status', PAID)">
          {{ $t('invoices_overview.header_paid') }}
        </div>
        <div class="PageMeta-tab" :class="{ 'is-void': filter.status === VOID }"
          @click="filter.update('status', VOID)">
          {{ $t('invoices_overview.header_void') }}
        </div>
      </template>
    </page-meta>
    <div class="PageContents">
      <div class="Container">

        <div class="Table">
          <overview-onboarding experience="invoices_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <div class="SpacerL"></div>
              <i class="Icon-Base--outlined">receipt</i>
            </div>
            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{ $t('onboarding_invoices.invoices_title') }}</h2>
              <div class="OnboardingIntro">
                {{ $t('onboarding_invoices.invoices_intro') }}
              </div>
              <p>{{$t('onboarding_invoices.invoices_instruction_line')}} <router-link to="/workspace/services">{{$t('onboarding_invoices.service')}}</router-link>
                {{$t('onboarding_invoices.creates_one')}}</p>
              <i18n path="onboarding_invoices.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_invoices.read_more.link')" target="_blank"
                    :title="$t('onboarding_invoices.read_more.title')">
                    {{ $t('onboarding_invoices.read_more.urltext') }}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>
          <div class="Table-header">
            <div class="Table-col--L">
              <sort :sort="filter.sort" field="number" @toggle="filter.update('sort', $event.sort)">
                {{ $t('invoices_overview.title') }}</sort>
            </div>
            <div class="Table-col--XL">
              <sort :sort="filter.sort" field="contact.name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('global.client') }}
              </sort>
            </div>
            <div class="Table-col--M">
              <sort :sort="filter.sort" field="date" @toggle="filter.update('sort', $event.sort)">
                {{ $t('invoices_overview.sent') }}
              </sort>
            </div>
            <div class="Table-col--M">
              <sort v-if="filter.status == 'paid'"
                :sort="filter.sort" field="paidDate" @toggle="filter.update('sort', $event.sort)">
                {{ $t('invoices_overview.paid') }}
              </sort>
              <sort v-else
                :sort="filter.sort" field="dueDate" @toggle="filter.update('sort', $event.sort)">
                {{ $t('invoices_overview.due') }}
              </sort>
            </div>
            <div class="Table-col--L Table-col--invTotal" v-if="exclTax">
              <sort :sort="filter.sort" field="totalExclTax" @toggle="filter.update('sort', $event.sort)">
                {{ $t('invoices_overview.total_excl_tax') }}
              </sort>
              <span class="change_a"><a @click="toggleExclTax">{{ $t('invoices_overview.change') }}</a></span>
            </div>
            <div class="Table-col--L Table-col--invTotal" v-else>
              <sort :sort="filter.sort" field="totalInclTax" @toggle="filter.update('sort', $event.sort)">
                {{ $t('invoices_overview.total_with_tax') }}</sort>
              <span class="change_a"><a @click="toggleExclTax">{{ $t('invoices_overview.change') }}</a></span>
            </div>
            <div class="Table-col--options" />
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>
          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{ $t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">
            <div v-for="invoice in invoices" :key="invoice.id" class="Table-row">
              <div class="Table-col--L Table-col--primary Item-Headline" @click="view(invoice)">
                  <div class="TableInvoiceNumber-Wrapper">
                    <div class="Invoice-Number">
                      {{ invoice.number }}
                    </div>
                    <div class="Table-Pills">
                      <span class="Pill--info"  v-if="invoice.isPaid">
                        {{ $t('global.invoice.paid') }}
                      </span>
                      <span class="Pill--info" v-if="!invoice.isPaid && invoice.status === 'sent'">
                        {{ $t('global.invoice.sent_and_unpaid') }}
                      </span>
                      <span class="Pill--warning" v-if="!invoice.isPaid && invoice.status === 'draft'">
                        {{ $t('global.invoice.draft') }}
                      </span>
                      <span class="Pill--mute" v-if="!invoice.isVoid && invoice.status === 'void'">
                        {{ $t('global.invoice.void') }}
                      </span>
                    </div>
                  </div>
                </div>
              <div class="Table-col--XL">
                {{ invoice.contact.name }}
              </div>
              <div class="Table-col--M">
                {{ invoice.date | moment('ll') }}
              </div>
              <div v-if="filter.status === 'paid'" class="Table-col--M">
                {{ invoice.paidDate | moment('ll') }}
              </div>
              <div v-else class="Table-col--M" :class="{ 'text-danger': invoice.isOverdue }">
                {{ invoice.dueDate | moment('ll') }}
              </div>
              <div v-if="exclTax" class="Table-col--L Table-col--invTotal">{{ invoice.totalExclTax | currency }}
                <small>+ {{ $t('global.tax') }}</small></div>
              <div v-else class="Table-col--L Table-col--invTotal">{{ invoice.totalInclTax | currency }}</div>
              <div class="Table-col--options" v-if="!invoice.isPaid">
                <setting-options
                  :options="optionObj[invoice.status]"
                  @view="view(invoice)"
                  @edit="edit(invoice)"
                  @send="send(invoice)"
                  @markPaid="markPaid(invoice)"
                />
              </div>
              <div v-else>
                <div class="Table-col--options">
                  <setting-options :options="optionObj[invoice.status]" @view="view(invoice)" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <pagination
          :show-all="totalItems < 500"
          :page="page"
          class="Pagination--lower"
          :num-pages="numPages"
          @change="loadPage($event.page)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.TableInvoiceNumber-Wrapper {
  display: grid;
  grid-template-columns: minmax(50px, max-content) 1fr;
  gap: $gapL;
}
</style>
