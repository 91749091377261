<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div class="Invoice">
    <div class="Invoice-Header">
      <h1 class="Invoice-Title">{{invoice.account.name}}</h1>
      <div class="Invoice-Subtitle">Tax Invoice for {{invoice.contact.name}}</div>
    </div>
    <div class="Invoice-Meta">
      <div class="Invoice-col--XL">
        <div class="Invoice-Label">From</div>
        <div class="Invoice-Address--top">
          <div>{{invoice.account.name}}</div>
          <div>{{invoice.account.address}}</div>
          <div>{{invoice.account.email}}</div>
        </div>
        <div class="Invoice-Label">For</div>
        <div class="Invoice-Address">
          {{invoice.contact.name}}
        </div>
      </div>
      <div class="Invoice-col--M"></div>
      <div class="Invoice-col--XL">
        <div class="Invoice-Label">Details</div>
        <div class="Invoice-Detail">
          <div class="Invoice-Detail-Label">Invoice #:</div>
          <div class="Invoice-Value">{{invoice.number}}</div>
        </div>
        <div class="SpacerS" />
        <div class="Invoice-Detail">
          <div class="Invoice-Detail-Label">Sent:</div>
          <div class="Invoice-Value">{{invoice.createdAt | moment('ll')}}</div>
        </div>
        <div class="Invoice-Detail">
          <div class="Invoice-Detail-Label">Due:</div>
          <div class="Invoice-Value">{{invoice.dueDate | moment('ll')}}</div>
        </div>
        <div class="SpacerS" />
        <div class="Invoice-Detail">
          <div class="Invoice-Detail-Label">Subtotal:</div>
          <div class="Invoice-Value">{{invoice.totalExclTax | currency}}</div>
        </div>
        <div class="Invoice-Detail">
          <div class="Invoice-Detail-Label">Tax:</div>
          <div class="Invoice-Value">{{invoice.taxAmount | currency}}</div>
        </div>
        <hr />
        <div class="Invoice-DetailTotal">
          <div class="Invoice-Detail-Label">Total:</div>
          <div class="Invoice-Value">{{invoice.totalInclTax | currency }}</div>
        </div>
      </div>
    </div>
    <div class="Invoice-Terms">
      <div class="Invoice-Label">Invoice Terms</div>
      {{invoice.account.settings.invoiceTerms}}
    </div>
    <div class="Bank-Details">
      <div class="Invoice-Label">Bank Details</div>
      {{ invoice.account.settings.bankDetails }}
    </div>
    <hr class="Invoice-Hr" />

    <div
      v-for="item in invoice.items"
      class="Invoice-Item"
    >
      <div class="Invoice-ItemInfo">
        <div class="Invoice-ItemName">{{item.offering.name}}</div>
        <div class="Invoice-ItemDesc">
          {{item.description}}
        </div>
        <div class="Invoice-Asset">
          {{item.asset.name}} ({{item.asset.type.name}})
        </div>
      </div>
      <div class="Invoice-ItemAmount">
        <div class="Invoice-ItemTotal">{{item.price | currency}}</div>
        <div class="Invoice-ItemTax">(excl tax)</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.Invoice {
  font-size: $fontSizeS;
  padding: 50px 40px 50px;
}

.Invoice-Header {
  text-align: left;
}

.Invoice-Title {
  padding: 0;
  margin: 0;
  font-size: 30px;
}
.Invoice-Subtitle {
  padding: 0;
  margin: 0 0 $spacing2XL;
  text-align: left;
  font-size: $fontSizeM;
  font-weight: $fontWeightNormal;
}

.Invoice-Meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing4XL;
}
.Invoice-Address--top {
  margin-bottom: $spacingL;
  white-space: pre-line;
}

.Invoice-Label {
  font-weight: bold;
  margin-bottom: $spacing2XS;
}

.Invoice-Detail,
.Invoice-DetailTotal {
  display: flex;
  justify-content: space-between;
}

.Invoice-DetailTotal {
  font-weight: $fontWeightBold;
  font-size: $fontSizeL;
}

.Invoice-Terms,
.Bank-Details {
  font-size: $fontSizeS;
  margin-bottom: $spacingXL;
}


.Invoice-Hr {
  margin-bottom: $spacingXL;
}

.Invoice-Item {
  margin-bottom: $spacing2XL;
  display: flex;

  .Invoice-ItemInfo {
    flex: 4;
    .Invoice-ItemName {
      font-weight: $fontWeightBold;
      font-size: $fontSizeM;
      margin-bottom: 5px;
    }

    .Invoice-ItemDescription {
      text-align: left;
      color: $colorTextFaded1;
      margin-bottom: 5px;
      font-size: $fontSizeS;
    }

    .Invoice-Asset {
      font-weight: $fontWeightBold;
    }
  }
  .Invoice-ItemAmount {
    flex: 1;
    text-align: right;
    .Invoice-ItemTotal {
      font-weight: $fontWeightBold;
      font-size: $fontSizeM;
    }
    .Invoice-ItemTax {
      font-size: $fontSizeXS;
      font-weight: $fontWeightNormal;
    }
  }
}

</style>