<script>
import { required, email } from 'vuelidate/lib/validators';
import AccountApi from '@/api/account.api';
import CashflowMenu from './settings-sidemenu';

export default {

  components: { CashflowMenu },

  async created() {
    await AccountApi
      .own()
      .then(account => {
        this.account = account;
        //MHJB: I don't know why, but I get an error in the template when attempting e.g. v-model="account.settings.invoiceLogo"
        this.settings = this.account.settings;
      });
  },

  data() {
    return {
      account: {},
      isSaving: false,
      settings: {},
    };
  },

  validations: {
    account: {
      email: {
        required,
        email,
      },
    },
  },

  methods: {
    async save() {
      const { $v, account } = this;

      $v.$touch();
      if ($v.$invalid) {
        return;
      }

      this.isSaving = true;
      await account.updateOwnSettings(account.settings);
      await account.updateOwn({ email: account.email });
      this.isSaving = false;
      this.$notice.show('notices.settings_updated');
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
        <cashflow-menu />
      </div>
      <div class="Content--mediumWidth">
        <h1 class="PageTitle">{{$t('account_invoice-settings.h1')}}</h1>

        <div class="GroupSet">
          <div class="Group" :class="{'Group--error': $v.account.email.$error}">
            <div class="Label">{{$t('account_invoice-settings.invoicing_email')}}</div>
            <div class="Label Description">{{$t('account_invoice-settings.invoicing_email_desc')}}</div>
            <input v-model="account.email" autocomplete="off" type="text" class="Input-M" :placeholder="$t('global.email')">
            <div class="InputHint" v-if="$v.account.email.$error">
              <div v-if="!$v.account.email.required">{{$t('account_invoice-settings.email_required')}}</div>
              <div v-if="!$v.account.email.email">{{$t('account_invoice-settings.invalid_email')}}</div>
            </div>
          </div>

          <div class="Group">
            <div class="Label">{{$t('account_invoice-settings.account_address')}}</div>
            <div class="Label Description">{{$t('account_invoice-settings.senders_address_for_invoice_desc')}}</div>
            <textarea v-model="account.address" autocomplete="off" type="textarea" class="Input-L" />
          </div>

          <!-- <div class="Group Group--inline">
            <div class="Label Label--inline">{{$t('account_invoice-settings.invoice_logo')}}</div>
            <input v-model="settings.invoiceLogo" autocomplete="off" type="text" class="Input-L" />
          </div> -->

          <div class="Group Group--inline">
            <div class="Label">{{$t('account_invoice-settings.invoice_bank')}}</div>
            <textarea v-model="settings.bankDetails" autocomplete="off" type="textarea" class="Input-L" />
          </div>

          <div class="Group Group--inline">
            <div class="Label">{{$t('account_invoice-settings.invoice_terms')}}</div>

            <textarea v-model="settings.invoiceTerms" autocomplete="off" type="textarea" class="Input-L" />
          </div>

          <div class="Group-inline Group--button">
            <button-bar :is-busy="isSaving" :show-cancel="false" :label-confirm="$t('shared_button-bar.save')"
              @confirm="save"></button-bar>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<style lang="scss">

</style>
