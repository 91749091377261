<script>
import Panel from '@/components/shared/panel';
import loadSummary from '@/helpers/load-summary';
import InvoiceApi from '@/api/invoice.api';
import ContractApi from '@/api/contract.api';
import AssetApi from '@/api/asset.api';
import ContactApi from '@/api/contact.api';
import UserApi from '@/api/user.api';

export default {
  components: { Panel },

  data() {
    return {
      stats: {
        assets: [],
        contacts: [],
        contracts: [],
        invoices: [],
      },

      loading: {
        assets: true,
        contacts: true,
        contracts: true,
        invoices: true,
      },
    }
  },

  computed: {
    showContracts() {
      return !this.stats.contracts.every(({ value }) => value === 0);
    },
    showAssets() {
      return !this.stats.assets.every(({ value }) => value === 0);
    },
    showBilling() {
      return !this.stats.invoices.every(({ value }) => value === 0);
    },
    showDirectory() {
      return !this.stats.contacts.every(({ value }) => value === 0);
    },
  },

  async created() {
    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Dashboard',
    });

    loadSummary('assets', AssetApi, {
      all: { label: 'Assets', query: 'assets' },
      noContract: {
        label: this.$t('dashboard_dashboard-items_panels.assets.no_contract'),
        query: '/workspace/assets/?hasContract=false',
        status: 'warning',
        hideIfZero: true,
      },
      noContact: {
        label: this.$t('dashboard_dashboard-items_panels.assets.no_owner'),
        query: '/workspace/assets/?hasContact=false',
        status: 'missing',
        hideIfZero: true,
      },
    }).then(assets => {
      this.stats.assets = assets;
      this.loading.assets = false;
    });

    const clientsPromise = loadSummary('contacts', ContactApi, {
      clients: {
        label: this.$t('dashboard_dashboard-items_panels.contacts.clients'),
        query: '/directory/clients/',
        hideIfZero: true,
      },
    });

    const usersPromise = loadSummary('contacts', UserApi, {
      users: { label: 'Users', query: '/directory/users' },
      invited: {
        label: this.$t('dashboard_dashboard-items_panels.contacts.invited'),
        query: '/directory/users',
        status: 'warning',
        hideIfZero: true,
      },
    });

    Promise.all([clientsPromise, usersPromise])
      .then(([clients, users]) => {
        this.stats.contacts = clients.concat(users);
        this.loading.contacts = false;
      });

    loadSummary('contracts', ContractApi, {
      active: {
        label: this.$t('dashboard_dashboard-items_panels.contracts.active'),
        query: 'services',
        status: 'active',
      },
      scheduled: {
        label: this.$t('dashboard_dashboard-items_panels.contracts.scheduled'),
        query: 'services?status=scheduled',
        status: 'scheduled',
      },
      finished: {
        label: this.$t('dashboard_dashboard-items_panels.contracts.finished'),
        query: 'services?status=finished',
        status: 'finished',
        hideIfZero: true,
      },
    }).then(contracts => {
      this.stats.contracts = contracts;
      this.loading.contracts = false;
    });

    loadSummary('invoices', InvoiceApi, {
      open: { label: this.$t('dashboard_dashboard-items_panels.billing.unpaid_invoices'), query: '/billing/invoices/' },
    }).then(invoices => {
      this.stats.invoices = invoices;
      this.loading.invoices = false;
    });
  },
};
</script>

<template>
  <div class="Dash--col Dash--col-1">
      <panel
        v-if="showContracts"
        :title="$t('dashboard_dashboard-items_panels.contracts.contracts')"
        icon="autorenew"
        panel_link="/workspace/services"
        :addNewable=false
        :stats="stats.contracts"
        :is-loading="loading.contracts"
      />

      <panel
      v-if="showAssets"
        :title="$t('dashboard_dashboard-items_panels.assets.assets')"
        icon="widgets"
        panel_link="/workspace/assets"
        :addNewable="false"
        :stats="stats.assets"
        :is-loading="loading.assets"
      />

    <panel
      v-if="showBilling"
      :title="$t('dashboard_dashboard-items_panels.billing.billing')"
      icon="attach_money"
      panel_link="/billing/invoices"
      :addNewable=false
      :stats="stats.invoices"
      :is-loading="loading.invoices"
    />

      <panel
        :title="$t('dashboard_dashboard-items_panels.contacts.directory')"
        icon="people"
        panel_link="/directory/clients"
        :addNewable=false
        :stats="stats.contacts"
        :is-loading="loading.contacts"
      />
  </div>
</template>
