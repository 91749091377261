<script>
import IntegrationApi from '@/api/integration.api';
import IntegrationTypes from '@/constants/integration-types';
import IntegrationLogos from '@/constants/integration-logos';
import OverviewOnboarding from '@/components/shared/overview-onboarding.vue';

export default {
  name: 'IntegrationsOverview',

  components: { OverviewOnboarding },

  data() {
    return {
      title: 'Integrations',
      integrations: [],
      map: {},
      isLoading: false,
      IntegrationTypes,
      IntegrationLogos,
    };
  },

  created() {
    this.$store.dispatch('page/setup', {
      title: 'Integrations',
    });
    this.loadIntegrations();
  },

  computed: {
    connected() {
      return Object.keys(IntegrationTypes)
        .filter(this.isConnected)
        .map((key) => ({
          type: key,
          name: IntegrationTypes[key],
        }));
    },

    available() {
      return Object.keys(IntegrationTypes)
        .filter((key) => !this.isConnected(key))
        .map((key) => ({
          type: key,
          name: IntegrationTypes[key],
        }));
    },
  },

  methods: {
    /** Go to an integration */
    goTo(type) {
      this.$router.push({ path: `/integrations/${type}` });
    },

    isConnected(type) {
      const { map } = this;
      return map[type] && map[type].isConnected;
    },

    isSetupIncomplete(type) {
      const { map } = this;
      return map[type] && map[type].setupIncomplete;
    },

    async loadIntegrations() {
      this.isLoading = true;
      this.integrations = await IntegrationApi.query().finally(
        () => (this.isLoading = false),
      );
      this.createIntegrationsMap();
    },

    createIntegrationsMap() {
      this.map = this.integrations.reduce((map, integration) => {
        map[integration.type] = integration;
        return map;
      }, {});
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="PageContents">
      <div class="Container">
        <div>
          <router-link class="settingsReturnLink u-breakPointUpToSmall" to="/account/menu">
            <i class="Icon-Base">keyboard_backspace</i> Back to Account Menu
          </router-link>

          <div v-if="isLoading">
            <spinner class="Spinner--general" />
          </div>

          <div v-if="!isLoading" class="Integrations Integrations-current">
            <h1 class="Title">{{ $t("global.installed_apps") }}</h1>

            <overview-onboarding experience="integrations_overview_onboarding">
              <div class="Section-Onboarding--Img">
                <img src="@/assets/images/onboard/apps.svg" alt="Integrations Onboarding">
              </div>
              <div class="Section-Onboarding--Content">
                <h2 class="OnboardingTitle">{{$t('onboarding_integrations.integrations_title')}}</h2>
                <div class="OnboardingIntro">
                  {{$t('onboarding_integrations.integrations_intro')}}
                </div>
                <p>{{$t('onboarding_integrations.integrations_instruction_line')}}</p>
                <i18n path="onboarding_integrations.read_more_integrations.text" tag="p" class="Section-Onboarding--Guidelink">
                  <template v-slot:url>
                    <a :href="$t('onboarding_integrations.read_more_integrations.link')" target="_blank"
                      :title="$t('onboarding_integrations.read_more_integrations.title')">
                      {{$t('onboarding_integrations.read_more_integrations.urltext')}}</a><i class="Icon-External">launch</i>
                  </template>
                </i18n>
              </div>
            </overview-onboarding>

            <div v-if="connected.length === 0" class="Block-noResults">
              {{$t('integrations_overview.no_results')}}
            </div>

            <div class="Blocks-Integrations-Wrap">
              <div class="Block-Integration" v-for="integration in connected" :key="integration.type"
                @click="goTo(integration.type)">
                <div class="Block-Header">
                  <div class="Block-Title-Text">
                    <img v-if="IntegrationLogos[integration.type]" class="Block-image"
                      :src="require(`@/assets/images/integrations/${IntegrationLogos[integration.type]}`)" alt="" />
                    <div>{{ IntegrationTypes[integration.type] }}</div>
                  </div>
                </div>
                <div class="Block-Content">
                  <div class="IntegrationStatus">
                    <div v-if="!isConnected(integration.type) || isSetupIncomplete(integration.type)"
                      class="Connection--fail">
                      <i class="Icon-Connection">error_outline</i>
                      {{ $t("integration.connection_issue") }}
                    </div>
                    <div v-else>
                      <div class="Connection--ok">
                        <i class="Icon-Connection">check_circle_outline</i>
                        <span>{{ $t("integration.connection_ok") }}</span>
                      </div>
                    </div>
                  </div>
                  <button class="Button--block">{{ $t("integration.configure") }}</button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!isLoading && available.length" class="Blocks-Extra">
            <div class="Title-subTitle">{{ $t("integration.recommended") }}
              <span class="Description">({{$t('integrations_overview.ready_to_connect')}})</span>
            </div>
            <div class="Blocks-Integrations-Wrap">
              <div class="Block-Integration" v-for="integration in available" :key="integration.type"
                @click="goTo(integration.type)">
                <div class="Block-Header">
                  <div class="Block-Title-Text">
                    <img v-if="IntegrationLogos[integration.type]" class="Block-image"
                      :src="require(`@/assets/images/integrations/${IntegrationLogos[integration.type]}`)" alt="" />
                    {{ integration.name }}
                  </div>
                </div>
                <div class="Block-Content">
                  <p>{{ $t(`integration.${integration.type}.teaser`) }}</p>
                </div>
                <button class="Button--block">{{ $t("integration.view_details") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.Connection--ok,
.Connection--fail {
  display: flex;
  padding: 0.65rem $spacingXL;
  font-size: $fontSizeM;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  width: 100%;

  .Icon-Connection {
    margin-right: $spacing2XS;
  }
}

.Connection--ok {
  .Icon-Connection {
    color: $colorOk;
  }

  color: $colorOk;
  background: $colorBgOk;

  &:hover {
    background: $colorBgOk;
  }
}

.Connection--fail {
  background: $colorBgDanger;
  color: $colorDanger;
  .Icon-Connection {
    color: $colorDanger;
  }

  &:hover {
    background: $colorBgDanger;
  }
}

.Integrations-current {
  padding-bottom: $spacingXL;
}

.IntegrationStatus {
  margin-bottom: $spacingXL;
}
</style>
