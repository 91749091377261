<script>
export default {
  name: 'ControllaSpinner',
};
</script>

<template>
  <div class="Spinner">
    <div class="Spinner-circle"></div>
  </div>
</template>

<style lang="scss">

//Spinner variables
$spinnerBorderSize: 3px;
$spinnerSize: 20px;
$spinnerAnimationDuration: 1s;

//Helper function
@function circlePosition($size, $borderSize, $coordinate, $alt: false) {
  $center: ($size * 0.5) - $borderSize - ($borderSize * 0.5);
  $radius: ($size * 0.5) - $borderSize + ($borderSize * 0.5);
  $root: 0.70710678118; //sqrt(2)/2
  @if $coordinate == x or $alt == true {
    @return round($center + ($radius * $root));
  }
  @else {
    @return round($center - ($radius * $root));
  }
}

//Spinner
.Spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: $spacingS 0;
}
.Button--primary .Spinner,
.Button--plain .Spinner,
.Button--login .Spinner,
.Button--register .Spinner,
.Button--oauth .Spinner {
  display: none;
  position: absolute;
  left: 0px;
}
.Spinner--inline {
  display: inline-block;
  margin-left: $spacingXL;
}
.Spinner-circle {
  width: $spinnerSize;
  height: $spinnerSize;
  border: $spinnerBorderSize solid transparent;
  @include spinnerColor($colorTextFaded3);
  border-radius: 50%;
  position: relative;
  animation: loaderRotate $spinnerAnimationDuration linear infinite;
  &:after {
    content: '';
    width: $spinnerBorderSize;
    height: $spinnerBorderSize;
    border-radius: 50%;
    position: absolute;
    top: circlePosition($spinnerSize, $spinnerBorderSize, y);
    left: circlePosition($spinnerSize, $spinnerBorderSize, x);
  }
  &:before {
    content: '';
    width: $spinnerBorderSize;
    height: $spinnerBorderSize;
    border-radius: 50%;
    position: absolute;
    top: circlePosition($spinnerSize, $spinnerBorderSize, y, true);
    left: circlePosition($spinnerSize, $spinnerBorderSize, x, true);
  }
  @keyframes loaderRotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.Spinner--general {
  .Spinner-circle {
    @include spinnerColor($colorTextFaded3);
  }
}

.Spinner--controlla {
  width: 96px;
  animation: rotation 4s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.Spinner--input {
  position: absolute;
  right: 0px;
  top: 2px;
  .Spinner-circle {
    @include spinnerColor($colorTextFaded3);
  }
}
</style>
