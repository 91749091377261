import BaseModel from './base.model';
import AssetApi from '@/api/asset.api';
import Contact from './contact.model';

export default class Asset extends BaseModel {
  getModelName() {
    return 'Asset';
  }

  /**
   * From JSON conversion
   */
  fromJSON(json) {
    super.fromJSON(json);
    this.convertToModel('contact', Contact);
    return this;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return AssetApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    return AssetApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;
    return AssetApi
      .remove(id);
  }

  archive() {
    return this.update({ archived: true });
  }

  unArchive() {
    return this.update({ archived: false });
  }
}
