<script>
import { mapState } from 'vuex';
import ContactApi from '@/api/contact.api';
import Contact from '@/models/contact.model';
import { PERSON, ORGANISATION } from '@/constants/contact-types';
import ContactEdit from './modals/client-edit';
import ContactView from './modals/client-view';
import ModalArchive from '@/components/shared/modal-archive';
import OverviewOnboarding from '@/components/shared/overview-onboarding.vue';

export default {
  components: { OverviewOnboarding },

  data() {
    return {
      title: 'Directory',
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      contacts: [],
      isLoading: false,

      //Constants
      PERSON,
      ORGANISATION,
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),

    hasItems() {
      return this.contacts.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  created() {
    this.setupFilter();
    this.setupPage();
    this.loadContacts();
    this.processAction();
  },

  methods: {
    setupFilter() {
      this.filter = this.$filter.get('contacts');

      this.filter.setDefaults({
        search: '',
        sort: 'name',
        type: null,
        from: null,
        to: null,
        by: '',
        isArchived: false,
      });

      this.filter.loadValues(this.$route.query);
      this.filter.onChange(() => this.loadPage(1));
    },

    setupPage() {
      const { title, filter } = this;
      this.$store.dispatch('page/setup', { title, filter });
    },

    async loadContacts() {
      this.isLoading = true;
      const filter = this.makeFilter();

      await ContactApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    async processAction() {
      const { action, id } = this.$route.params;

      if (action === 'add') {
        return this.add();
      }

      if (action === 'edit' && id) {
        const contact = await ContactApi.findById(id);
        if (contact) {
          this.edit(contact);
        }
      }

      else if (id) {
        const contact = await ContactApi.findById(id);

        if (contact) {
          this.view(contact);
        }
      }
    },

    add() {
      const contact = new Contact();
      this.edit(contact, false);
    },

    async view(contact) {
      this.$store.dispatch('session/loadAccount');

      const onEdit = async () => this.edit(contact);

      const onRemove = () => contact.remove().then(this.onRemoved);

      this.$modal.show(
        ContactView,
        { contact, onEdit, onRemove },
        { maxWidth: 1000 },
        {
          opened: () => this.$router.push({ name: 'clients', params: { action: 'view', id: contact.id } }),
          closed: () => this.$router.replace({ name: 'clients' }),
        }
      );
    },

    edit(contact, isEdit = true) {
      const onSave = model => contact
        .save(model)
        .then(() => this.onSaved(contact, isEdit));

      this.$modal.show(
        ContactEdit,
        { contact, isEdit, onSave },
        { maxWidth: 500 },
        {
          opened: () => this.$router.replace({ name: 'clients', params: { action: 'edit', id: contact.id } }),
          closed: () => this.$router.replace({ name: 'clients' }),
        }
      );
    },

    async onSaved(contact, isEdit) {
      this.$notice.show('notices.client_saved');
      const updatedContact = await ContactApi.findById(contact.id);
      this.view(updatedContact);

      //Reload contacts if we added a new one
      if (!isEdit) {
        this.loadContacts(true);
      }

      // if this is the first contact added, set user's experiences.client_added flag (for dashboard tick)
      const { user } = this;
      if (!user.experiences.client_added) {
        user.experiences.client_added = true;
        user.save();
      }
    },

    onRemoved() {
      this.$notice.show('notices.client_removed');
      this.loadContacts(true);
    },

    loadPage(page) {
      this.page = page;
      this.loadContacts(true);
    },

    makeFilter(extra) {
      //Initialize filter
      const filter = this.filter.toJSON();
      const { page, itemsPerPage } = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const { meta, contacts } = data;
      this.contacts = contacts;
      this.totalItems = meta.total;
    },

    archive(contact) {
      const onArchive = () => contact.archive().then(this.onArchived);

      this.$modal.show(ModalArchive,
        {
          item: contact,
          onArchive,
          text: `${this.$t('confirmation.confirm_archive_contact')} <strong>${contact.name}</strong>?`,
        },
      );
    },

    onArchived() {
      this.$notice.show('notices.client_archived');
      this.loadContacts();
    },

    unArchive(contact) {
      return contact.unArchive().then(this.onUnArchived);
    },

    onUnArchived() {
      this.$notice.show('notices.client_restored');
      this.loadContacts(true);
    },
  },
};
</script>
<template>
  <div class="Page">
    <page-meta type="global.client"
      :title="title"
      :filter="filter"
      :total-items="totalItems"
      :page="page"
      :num-pages="numPages"
      :loadPage="loadPage"
      @add="add"
    >
      <template slot="settings">
        <setting-options>
          <li>
            <router-link to="/workspace/assets/assetTypes"><i class="Icon-Base">widgets</i>{{$t('shared_settings-options.asset_types')}}</router-link>
          </li>
          <li>
            <router-link to="/integrations"><i class="Icon-Base">device_hub</i>{{$t('shared_settings-options.integrations')}}</router-link>
          </li>
          <hr />
          <li>
            <a :href="$t('shared_settings-options.help_clients_link')" target="_blank">
              <i class="Icon-Base">help</i>{{$t('shared_settings-options.help_clients')}} <i class="Icon-External">launch</i>
            </a>
          </li>
        </setting-options>
      </template>
      <template slot="tabs">
        <div class="PageMeta-tab--first" :class="{ 'is-active': (!filter.isArchived) }"
          @click="filter.update('isArchived', false)">
          {{ $t('directory_clients.filter.type.all') }}
        </div>
        <div class="PageMeta-tab" :class="{ 'is-active': (filter.isArchived) }"
          @click="filter.update('isArchived', true)">
          {{ $t('directory_clients.filter.type.archived') }}
        </div>
      </template>
    </page-meta>
    <div class="PageContents">
      <div class="Container">

        <div class="Table">
          <overview-onboarding experience="clients_overview_onboarding">
            <div class="Section-Onboarding--Img">
              <div class="SpacerL"></div>
              <i class="Icon-Base">people</i>
            </div>
            <div class="Section-Onboarding--Content">
              <h2 class="OnboardingTitle">{{ $t('onboarding_clients.clients_title') }}</h2>
              <div class="OnboardingIntro">
                {{ $t('onboarding_clients.clients_intro') }}
              </div>
              <p>
                <router-link to="/integrations/xero">{{ $t('onboarding_clients.connect_xero') }}</router-link> {{ $t('onboarding_clients.connect_xero_purpose') }}
              </p>
              <i18n path="onboarding_clients.read_more.text" tag="p" class="Section-Onboarding--Guidelink">
                <template v-slot:url>
                  <a :href="$t('onboarding_clients.read_more.link')" target="_blank"
                    :title="$t('onboarding_clients.read_more.title')">
                    {{ $t('onboarding_clients.read_more.urltext') }}</a><i class="Icon-External">launch</i>
                </template>
              </i18n>
            </div>
          </overview-onboarding>
          <div class="Table-header">
            <div class="Table-col--M">
              <sort :sort="filter.sort" field="name" @toggle="filter.update('sort', $event.sort)">
                {{ $t('directory_clients.name') }}</sort>
            </div>

            <div class="Table-col--S"></div>

            <div class="Table-col--L">
              <sort :sort="filter.sort" field="email" @toggle="filter.update('sort', $event.sort)">
                {{ $t('directory_clients.billing_contact') }}</sort>
            </div>

            <div class="Table-col--options" />
          </div>

          <div v-if="isLoading && !hasItems">
            <spinner class="Spinner--general" />
          </div>
          <div v-if="!isLoading && !hasItems" class="Table-noResults">
            {{ $t('global.table_no_results') }}
          </div>

          <div v-if="hasItems">

            <div v-for="contact in contacts" :key="contact.id" class="Table-row">
              <div class="Table-col--M Table-col--primary" @click="view(contact)">
                <div class="ItemM">
                  <div class="Item-Icon">
                    <div class="Icon-Base--filled" v-if="contact.type === 'person'">person</div>
                    <div class="Icon-Base--filled" v-else-if="contact.type === 'organisation'">groups</div>
                    <div class="Icon-Base--danger" v-else>error_outline</div>
                  </div>
                  <div class="Item-TextWrap">
                    <div class="Item-Headline">
                      {{ contact.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="Table-col--S">
                <div class="Table-Tally">
                  <span  class="Table-Tally--asset" v-if="( contact.assetCount )">{{ contact.assetCount }} {{$tc('global.asset', contact.assetCount)}}</span>
                  <span class="Table-Tally--service" v-if="( contact.contractCount )">{{contact.contractCount}} {{$tc('global.service_plural', contact.contractCount)}}</span>
                </div>
              </div>


              <div class="Table-col--L">
                <div v-if="(contact.type == 'organisation')">
                  <div class="ClientContact-Wrap">
                    <div v-if="((!contact.contactPerson)||(!contact.email))">
                      <span class="Table-Value--exception">
                        <i class="Icon-Base--warning">info</i><a @click="edit(contact)">{{ $t('directory_clients.add_billing_contact_details')
                        }}</a>
                      </span>
                    </div>
                    <div v-if="contact.contactPerson" class="ClientContact-Person">
                      {{ contact.contactPerson }}
                    </div>
                  </div>
                  <div v-if="contact.email" class="Description">
                    {{ contact.email }}
                  </div>
                </div>

                <div v-else-if="contact.type == 'person'">
                  <div class="ClientContact-Wrap">
                    <div class="ClientContact-Person">
                      {{ contact.name}}
                    </div>
                    <div v-if="!contact.email">
                      <span class="Table-Value--exception">
                        <i class="Icon-Base--warning">info</i><a @click="edit(contact)">{{ $t('directory_clients.add_billing_contact_email')
                        }}</a>
                      </span>
                    </div>
                  </div>

                  <div v-if="contact.email" class="Description">
                    {{ contact.email }}
                  </div>
                </div>
                <div v-else>
                  <span class="Table-Value--exception">
                    <i class="Icon-Base--danger">error_outline</i><a @click="edit(contact)">{{ $t('directory_clients.add_client_details')
                    }}</a>
                  </span>
                </div>
              </div>

              <div class="Table-col--options">
                <setting-options v-if="contact.archived" :options="['view', 'unArchive']" @view="view(contact)"
                  @un-archive="unArchive(contact)" />
                <setting-options v-else-if="contact.contractCount === 0 && contact.assetCount === 0"
                  :options="['view', 'edit', 'archive']" @view="view(contact)" @edit="edit(contact)"
                  @archive="archive(contact)" />
                <setting-options v-else :options="['view', 'edit', 'archive-disabled']" @view="view(contact)"
                  @edit="edit(contact)" />
              </div>
            </div>
          </div>
        </div>
        <pagination :show-all="totalItems < 500" :page="page" class="Pagination--lower" :num-pages="numPages"
          @change="loadPage($event.page)" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.ClientContact-Wrap {
  display: flex;
  flex-direction: column;
  gap: 3px;

}

.ClientContact-Person {
  font-weight: $fontWeightMedium;
}
</style>