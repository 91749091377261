<script>
import { mapState } from 'vuex';
import ModalConfirm from '@/components/shared/modal-confirm';

export default {
  name: 'UserProfile',

  components: { ModalConfirm },

  data() {
    return {
      model: {
        name: '',
        email: '',
      },
      isSaving: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),
  },

  created() {
    this.setupPage();

    //Create model subset
    this.model = this.user.extract(Object.keys(this.model));
  },

  methods: {
    setupPage() {
      this.$store.dispatch('page/setup', {
        title: 'User Profile',
      });
    },

    async save() {
      const { user, model } = this;
      this.isSaving = true;
      await user.updateOwn(model).finally(() => {
        this.isSaving = false;
        this.$notice.show('notices.settings_updated');
      });
      this.model.password = '';
    },

    resetOnboarding() {
      const onConfirm = async () => {
        // these are a subset of controlla-node/app/components/user/user.ctrl.js
        const experiences = {
          onboarding_panel: true,
          onboarding_panel_stripe: true,
          contracts_overview_onboarding: true,
          assets_overview_onboarding: true,
          offerings_overview_onboarding: true,
          invoices_overview_onboarding: true,
          payments_overview_onboarding: true,
          creditcards_overview_onboarding: true,
          clients_overview_onboarding: true,
          integrations_overview_onboarding: true,
        };
        Object.assign(this.user.experiences, experiences);
        await this.user.updateOwn(this.user);
        this.$notice.show('notices.onboarding_reset');
      };

      this.$modal.show(
        ModalConfirm,
        {
          _modalTitle: this.$t('profile_profile.title_onboarding_reset'),
          _modalMessage: this.$t('profile_profile.message_onboarding_reset'),
          _buttonText: this.$t('profile_profile.button_onboarding_reset'),
          _buttonType: 'ok',
          onConfirm,
        },
      );
    },
  },
};
</script>

<template>
  <div class="Page">
    <div class="InnerPage-Wrap--sidemenu">
      <div class="Menu--col">
      </div>
      <div class="Content--mediumWidth">
        <div class="Profile-Wrap">
          <div class="Avatar-fullsize">
            <span class="UserAvatar">{{ user ? user.initials : "" }}</span>
          </div>
          <div class="ProfileWrap">
            <h1 class="PageTitle">{{ $t("global.my_profile") }}</h1>

            <div class="GroupSet">
              <div class="Group Group--inline">
                <div class="Label Label--inline">{{$t('profile_profile.name')}}</div>
                <input v-model="model.name" autocomplete="off" type="text" class="Input-M" :placeholder="$t('global.name')" />
              </div>
              <div class="Group Group--inline">
                <div class="Label Label--inline">{{$t('profile_profile.email')}}</div>
                <div class="Input-Wrap">
                  <input v-model="model.email" autocomplete="off" :disabled="user.hasGoogleProfile" type="text" class="Input-M" :placeholder="$t('global.email')" />
                  <div class="SpacerXS" />
                  <div v-if="user.hasGoogleProfile" class="Description">{{ $t('profile_profile.profile_linked_to_google_account') }}</div>
                </div>
              </div>
              <div class="Group Group--inline">
                <div class="Label Label--inline">{{$t('profile_profile.password')}}</div>
                <input v-model="model.password" autocomplete="new-password" type="password" class="Input-M"
                  placeholder="New password" />
              </div>
              <div class="Group-inline Group--button">
                <button-bar :is-busy="isSaving" :show-cancel="false" label-confirm="Save" @confirm="save"></button-bar>
              </div>
            </div>
            <div class="SpacerM" />
            <div class="button-flex-wrap">
              <a class="Button--justText-resetOnboarding" @click="resetOnboarding">
                {{$t('profile_profile.reset_onboarding')}}
              </a>
            </div>
            <div class="Spacer2XS" />
            <div class="Description">{{$t('profile_profile.reset_onboarding_note')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .Profile-Wrap {
    display: flex;
  }
</style>